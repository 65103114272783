import type { VariantProps} from 'class-variance-authority';

import { cn } from '#app/utils/misc';
import { cva } from 'class-variance-authority';
import React from 'react';



const chipVariants = cva(
  'grid items-center relative w-fit rounded-full cursor-pointer grid grid-flow-col justify-center',
  {
    compoundVariants: [
      {
        className: 'text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium',
        size     : 'large',
        variant  : 'gray'
      },
      {
        className: 'text-caption-1-medium xs:font-sfProMedium sm:font-pretendardMedium',
        size     : 'small',
        variant  : 'gray'
      },
      {
        className: 'text-caption-1-bold font-pretendardBold',
        size     : 'small',
        variant  : 'black'
      },
      {
        className: 'text-caption-1-bold font-pretendardBold',
        size     : 'small',
        variant  : 'primary'
      },
      {
        className: 'text-label-1-bold font-pretendardBold',
        size     : 'large',
        variant  : 'black'
      },
      {
        className: 'text-label-1-bold font-pretendardBold',
        size     : 'large',
        variant  : 'primary'
      },
    ],
    defaultVariants: {
      size   : 'small',
      variant: 'gray'
    },
    variants: {
      disabled: {
        true: 'cursor-not-allowed pointer-events-none',
      },
      size: {
        large: 'justify-center text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium',
        small: 'justify-center text-caption-1-medium xs:font-sfProMedium sm:font-pretendardMedium',
      },
      variant: {
        black  : 'bg-gray-100 text-white',
        gray   : 'bg-gray-10 text-gray-90',
        primary: 'bg-point-blue-70 text-white',
      }
    }
  }
);

interface ChipProps extends React.HTMLAttributes<HTMLButtonElement>, VariantProps<typeof chipVariants> {
  badge?: boolean;
  disabled?: boolean;
  name: string;
  postFixNode?: React.ReactNode
  preFixNode?: React.ReactNode
  size?: 'large' | 'small',
  type?: 'button' | 'submit';

  value: string;
}

const Chip: React.FC<ChipProps> = ({ disabled, name, onClick, postFixNode, preFixNode, size, type, value, variant, ...props }) => {
  return (
    <button
      className={cn(chipVariants({ disabled, size, variant }))}
      name={name}
      onClick={onClick}
      type={type ?? 'button'}
      value={value}
      {...props}
    >
      {
        props.badge && (
          <div className={cn('absolute top-0 right-0 w-3 h-3 border-solid border-2 border-white rounded-full bg-pink-70')} />
        )
      }
      <div className={cn(
        'grid grid-flow-col rounded-full gap-1', 'grid items-center', 'active:bg-alpha-1.5 p-3')}>
        {preFixNode}
        {props.children}
        {postFixNode}
      </div>
    </button>
  );
};
export default Chip;